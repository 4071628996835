import axios from '@/api/config/interceptor'
import { host } from '@/api/config/host'
import { _paramsToQueryString } from '@/utils/utils'

//上传运单
export const waterTransExcel = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/File/WaterTransExcel`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

//上传照片
export const uploadImages = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/WaterTrans/Images`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

//通过运单id查一条
export const waterTransBillsDetailsById = (params) => {
    return new Promise((resolve, reject) => {
        axios.get(`${host}/api/WaterTrans/WaterTransBillsDetailsById?${_paramsToQueryString(params)}`).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

//提交资料
export const keepBills = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/WaterTrans/KeepBills`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

//获取水运列表
export const getWaterTransBillsDetails = (params) => {
    return new Promise((resolve, reject) => {
        axios.get(`${host}/api/WaterTrans/GetWaterTransBillsDetails?${_paramsToQueryString(params)}`).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

//删除运单
export const deleteWaterTransBills = (params) => {
    return new Promise((resolve, reject) => {
        axios.delete(`${host}/api/WaterTrans/DeleteWaterTransBills?${_paramsToQueryString(params)}`).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

//更新保存水运信息
export const updateBills = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/WaterTrans/UpdateBills`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

//更新承运人信息
export const updatePersonImageInfo = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/WaterTrans/UpdatePersonImageInfo`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

//更新船舶信息
export const updateShipImageInfo = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/WaterTrans/UpdateShipImageInfo`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

//更新合同照片
export const updateContractImage = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/WaterTrans/UpdateContractImage`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

//更新轨迹照片
export const updateTransImage = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/WaterTrans/UpdateTransImage`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

//更新付款凭证照片
export const updateCertificImage = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/WaterTrans/UpdateCertificImage`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

//更新其他凭证照片
export const updateOtherImage = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/WaterTrans/UpdateOtherImage`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

//更新发票照片
export const updateInvoiceImage = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/WaterTrans/UpdateInvoiceImage`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}
