<template>
  <div class="waterTransportPage">
    <div class="facilityBox">
      <el-button type="primary" @click="$router.push('/platform/waterTransport/add')">上传运单</el-button>
      <el-button type="primary" @click="downloadTemplate">下载模板</el-button>
      <br>
      <!-- 搜索表单 -->
      <el-form ref="formData" :model="formData" :inline="true" label-width="80px" style="margin-top: 16px;">
        <el-form-item label="托运人" prop="shipper" style="width:300px">
          <el-input v-model="formData.shipper"></el-input>
        </el-form-item>
        <el-form-item label="承运人" prop="carrier" style="width:300px">
          <el-input v-model="formData.carrier"></el-input>
        </el-form-item>
        <el-form-item label="运单号" prop="billId" style="width:300px">
          <el-input v-model="formData.billId"></el-input>
        </el-form-item>
        <el-form-item label="发票号" prop="invoiceNum">
          <el-input v-model="formData.invoiceNum"></el-input>
        </el-form-item>
        <el-form-item style="margin-left: 30px;">
          <el-button type="primary" @click="search" icon="el-icon-search">搜索</el-button>
          <el-button type="primary" icon="el-icon-delete" @click="reset">清空</el-button>
          <div @click="flag.showSelect = !flag.showSelect" style="color: #409EFF; display: inline-block; margin-left: 30px; cursor:pointer;">
            <span>{{flag.showSelect ? '收起' : '展开'}}</span><i :class="flag.showSelect ? 'el-icon-arrow-up' : 'el-icon-arrow-down'" style="margin-left: 10px; line-height: 30px"></i>
          </div>
        </el-form-item>
        <el-collapse-transition>
          <div style="display: flex; flex-wrap: wrap;" v-show="flag.showSelect">
            <el-form-item label="起始地" prop="startingPoint" style="width:300px">
              <el-input v-model="formData.startingPoint"></el-input>
            </el-form-item>
            <el-form-item label="到达地" prop="destination" style="width:300px">
              <el-input v-model="formData.destination"></el-input>
            </el-form-item>
            <el-form-item label="船名" prop="shipsName" style="width:300px">
              <el-input v-model="formData.shipsName"></el-input>
            </el-form-item>
            <el-form-item label="提交时间" prop="createTime">
              <el-date-picker style="width:530px" v-model="formData.createTime" type="daterange" value-format="yyyy-MM-dd" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
            </el-form-item>
            <el-form-item label="起运时间" prop="time">
              <el-date-picker style="width:530px" v-model="formData.time" type="daterange" value-format="yyyy-MM-dd" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
            </el-form-item>
          </div>
        </el-collapse-transition>
      </el-form>

      <!-- 表单 -->
      <el-table :data="tableList1" v-loading="pagination1.loading" :header-cell-style="{color: '#666', background: '#f0f0f0'}">
        <el-table-column fixed align="center" prop="WaterBillNum" label="运单号" width="200" show-overflow-tooltip></el-table-column>
        <el-table-column align="center" prop="Shipper" label="托运人" width="300" show-overflow-tooltip></el-table-column>
        <el-table-column align="center" label="承运人" width="100">
          <template slot-scope="scope">
            <div style="color:blue;cursor: pointer;" @click="openPersonDialog(scope.row)">{{scope.row.Carrier}}</div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="CarrierIDCard" label="承运人身份证号码" width="200" show-overflow-tooltip></el-table-column>
        <el-table-column align="center" label="船名" width="200">
          <template slot-scope="scope">
            <div style="color:blue;cursor: pointer;" @click="openShipDialog(scope.row)">{{scope.row.ShipsName}}</div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="ShipsType" label="船舶种类" width="200" show-overflow-tooltip></el-table-column>
        <el-table-column align="center" prop="GoodsName" label="货名" width="200" show-overflow-tooltip></el-table-column>
        <el-table-column align="center" prop="Unit" label="单位" width="200" show-overflow-tooltip></el-table-column>
        <el-table-column align="center" prop="Num" label="数量" width="200" show-overflow-tooltip></el-table-column>
        <el-table-column align="center" label="总金额（元）" width="200">
          <template slot-scope="scope">
            <div style="color:#FF8B17;">￥{{scope.row.TotalAmount}}</div>
          </template>
        </el-table-column>
        <el-table-column align="center" label="起始地" width="200">
          <template slot-scope="scope">
            <div style="color:blue;cursor: pointer;" @click="openTrackDialog(scope.row)">{{scope.row.StartingPoint}}</div>
          </template>
        </el-table-column>
        <el-table-column align="center" label="到达地" width="200">
          <template slot-scope="scope">
            <div style="color:blue;cursor: pointer;" @click="openTrackDialog(scope.row)">{{scope.row.Destination}}</div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="DepartureTime" label="起运时间" width="200" show-overflow-tooltip></el-table-column>
        <el-table-column align="center" prop="ArrivalTime" label="到达时间" width="200" show-overflow-tooltip></el-table-column>
        <el-table-column align="center" prop="EmergencyContactPhone" label="联系人号码" width="200" show-overflow-tooltip></el-table-column>
        <el-table-column align="center" prop="InvoiceNumber" label="发票号码" width="300" show-overflow-tooltip></el-table-column>
        <el-table-column align="center" prop="InvoiceTime" label="开票日期" width="200" show-overflow-tooltip></el-table-column>
        <el-table-column align="center" prop="CreateTime" label="提交时间" width="200" show-overflow-tooltip></el-table-column>
        <el-table-column fixed="right" align="center" label="操作" width="550">
          <template slot-scope="scope">
            <el-button size="mini" type="primary" @click="openAllInfoDialog(scope.row)">详情</el-button>
            <el-button size="mini" type="primary" @click="openBasicEditDialogDialog(scope.row)" v-if="flag.enableEdit">编辑</el-button>
            <el-button size="mini" type="primary" @click="openInvoiceDialog(scope.row)">发票</el-button>
            <el-button size="mini" type="primary" @click="openContractDialog(scope.row)">合同</el-button>
            <el-button size="mini" type="primary" @click="openPayCertifictyDialog(scope.row)">付款凭证</el-button>
            <el-button size="mini" type="primary" @click="openOtherDialog(scope.row)">其它凭证</el-button>
            <el-button size="mini" type="danger" @click="deleteWaybill(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination background class="pagination" style="margin-top:8px" @current-change="pagination1Change" :current-page.sync="pagination1.page" :page-size="pagination1.pagesize" layout="total, prev, pager, next, jumper" :total="pagination1.total">
      </el-pagination>

      <!-- 基本信息编辑dialog -->
      <el-dialog dialog title="基本信息" :visible.sync="flag.basicEditDialog" v-if="JSON.stringify(currentItem) != '{}' && flag.enableEdit" width="1200px">
        <el-form :inline="true" :model="currentItem" label-width="130px">
          <el-form-item prop="WaterBillNum" label="运单号">
            <el-input v-model="currentItem.WaterBillNum" disabled></el-input>
          </el-form-item>
          <el-form-item prop="Shipper" label="托运人">
            <el-input v-model="currentItem.Shipper"></el-input>
          </el-form-item>
          <el-form-item prop="CarrierIDCard" label="承运人身份证号码">
            <el-input v-model="currentItem.CarrierIDCard"></el-input>
          </el-form-item>
          <el-form-item prop="ShipsName" label="船名">
            <el-input v-model="currentItem.ShipsName"></el-input>
          </el-form-item>
          <el-form-item prop="Carrier" label="承运人">
            <el-input v-model="currentItem.Carrier"></el-input>
          </el-form-item>
          <el-form-item prop="ShipsType" label="船舶种类">
            <el-input v-model="currentItem.ShipsType"></el-input>
          </el-form-item>
          <el-form-item prop="GoodsName" label="货名">
            <el-input v-model="currentItem.GoodsName"></el-input>
          </el-form-item>
          <el-form-item prop="Unit" label="单位">
            <el-input v-model="currentItem.Unit"></el-input>
          </el-form-item>
          <el-form-item prop="Num" label="数量">
            <el-input v-model="currentItem.Num"></el-input>
          </el-form-item>
          <el-form-item prop="TotalAmount" label="总金额（元）">
            <el-input v-model="currentItem.TotalAmount"></el-input>
          </el-form-item>
          <el-form-item prop="StartingPoint" label="起始地">
            <el-input v-model="currentItem.StartingPoint"></el-input>
          </el-form-item>
          <el-form-item prop="Destination" label="到达地">
            <el-input v-model="currentItem.Destination"></el-input>
          </el-form-item>
          <el-form-item prop="DepartureTime" label="起运时间">
            <el-date-picker v-model="currentItem.DepartureTime" type="datetime" value-format="yyyy-MM-dd HH:mm:ss" placeholder="起运时间"></el-date-picker>
          </el-form-item>
          <el-form-item prop="ArrivalTime" label="到达时间">
            <el-date-picker v-model="currentItem.ArrivalTime" type="datetime" value-format="yyyy-MM-dd HH:mm:ss" placeholder="到达时间"></el-date-picker>
          </el-form-item>
          <el-form-item prop="EmergencyContactPhone" label="联系人号码">
            <el-input v-model="currentItem.EmergencyContactPhone"></el-input>
          </el-form-item>
          <el-form-item prop="InvoiceNumber" label="发票号码">
            <el-input v-model="currentItem.InvoiceNumber"></el-input>
          </el-form-item>
          <el-form-item prop="InvoiceTime" label="开票日期">
            <el-date-picker v-model="currentItem.InvoiceTime" type="date" value-format="yyyy-MM-dd" placeholder="开票日期"></el-date-picker>
          </el-form-item>
          <el-form-item prop="CreateTime" label="提交时间">
            <el-date-picker v-model="currentItem.CreateTime" type="datetime" value-format="yyyy-MM-dd HH:mm:ss" placeholder="提交时间"></el-date-picker>
          </el-form-item>
        </el-form>
        <div style="display:flex; justify-content: center;">
          <el-button type="primary" v-if="flag.enableEdit" @click="updateBills(currentItem)">保存</el-button>
        </div>
      </el-dialog>

      <!-- 船舶信息dialog -->
      <el-dialog dialog title="船舶信息" :visible.sync="flag.shipDialog" v-if="JSON.stringify(currentItem) != '{}' && !flag.enableEdit" width="1200px">
        <el-form :inline="true" :model="currentItem.ship" label-width="130px">
          <div style="display: flex; margin-bottom: 16px;">
            <el-form-item prop="shipImg1" label="船舶证1" v-if="currentItem.ship.shipImg1.picUrl">
              <el-image style="width: 177px; height: 177px; margin: 0 8px; border: 1px dashed #bbb" :src="currentItem.ship.shipImg1.picUrl" :preview-src-list="[currentItem.ship.shipImg1.picUrl]"></el-image>
            </el-form-item>
            <el-form-item prop="shipImg1" label="船舶证2" v-if="currentItem.ship.shipImg2.picUrl">
              <el-image style="width: 177px; height: 177px; margin: 0 8px; border: 1px dashed #bbb" :src="currentItem.ship.shipImg2.picUrl" :preview-src-list="[currentItem.ship.shipImg2.picUrl]"></el-image>
            </el-form-item>
          </div>
          <div>
            <el-form-item prop="number" label="编号">
              <el-input v-model="currentItem.ship.number" disabled></el-input>
            </el-form-item>
            <el-form-item prop="shipsName" label="船名">
              <el-input v-model="currentItem.ship.shipsName" disabled></el-input>
            </el-form-item>
            <el-form-item prop="formerName" label="曾用名">
              <el-input v-model="currentItem.ship.formerName" disabled></el-input>
            </el-form-item>
          </div>
          <div>
            <el-form-item prop="homePort" label="船籍港">
              <el-input v-model="currentItem.ship.homePort" disabled></el-input>
            </el-form-item>
            <el-form-item prop="shipRegistrationNumber" label="船舶登记号">
              <el-input v-model="currentItem.ship.shipRegistrationNumber" disabled></el-input>
            </el-form-item>
            <el-form-item prop="surveyRegistratioNumber" label="船检登记号">
              <el-input v-model="currentItem.ship.surveyRegistratioNumber" disabled></el-input>
            </el-form-item>
          </div>
          <div>
            <el-form-item prop="shipowner" label="船舶所有人">
              <el-input v-model="currentItem.ship.shipowner" disabled></el-input>
            </el-form-item>
            <el-form-item prop="shipOperator" label="船舶经营人">
              <el-input v-model="currentItem.ship.shipOperator" disabled></el-input>
            </el-form-item>
            <el-form-item prop="operatorPermitNumber" label="经营人许可证号码">
              <el-input v-model="currentItem.ship.operatorPermitNumber" disabled></el-input>
            </el-form-item>
          </div>
          <div>
            <el-form-item prop="serviceLife" label="使用期限">
              <el-date-picker v-model="currentItem.ship.serviceLife" type="date" value-format="yyyy-MM-dd" disabled></el-date-picker>
            </el-form-item>
            <el-form-item prop="licenceIssuingAuthority" label="发证机关">
              <el-input v-model="currentItem.ship.licenceIssuingAuthority" disabled></el-input>
            </el-form-item>
            <el-form-item prop="dateOfIssue" label="发证日期">
              <el-date-picker v-model='currentItem.ship.dateOfIssue' type="date" value-format="yyyy-MM-dd" disabled></el-date-picker>
            </el-form-item>
          </div>
          <div>
            <el-form-item prop="shipType" label="船舶类型">
              <el-input v-model="currentItem.ship.shipType" disabled></el-input>
            </el-form-item>
            <el-form-item prop="shipMaterial" label="船舶材料">
              <el-input v-model="currentItem.ship.shipMaterial" disabled></el-input>
            </el-form-item>
            <el-form-item prop="shipGrossTonnage" label="船舶总吨">
              <el-input v-model="currentItem.ship.shipGrossTonnage" disabled></el-input>
            </el-form-item>
          </div>
          <div>
            <el-form-item prop="completionDate" label="建成日期">
              <el-date-picker v-model="currentItem.ship.completionDate" type="date" value-format="yyyy-MM-dd" disabled></el-date-picker>
            </el-form-item>
            <el-form-item prop="redevelopmentDate" label="改建日期">
              <el-date-picker v-model="currentItem.ship.redevelopmentDate" type="date" value-format="yyyy-MM-dd" disabled></el-date-picker>
            </el-form-item>
            <el-form-item prop="mainMotorPower" label="主机功率">
              <el-input v-model="currentItem.ship.mainMotorPower" disabled></el-input>
            </el-form-item>
          </div>
          <div>
            <el-form-item prop="allowScope" label="船舶经营人许可证核定的经营范围" label-width="230px">
              <el-input class="row" v-model="currentItem.ship.allowScope" disabled></el-input>
            </el-form-item>
          </div>
          <div>
            <el-form-item prop="stipulateScope" label="本船核定的经营范围" label-width="250px">
              <el-input class="row" v-model="currentItem.ship.stipulateScope" disabled></el-input>
            </el-form-item>
          </div>
        </el-form>
      </el-dialog>
      <!-- 船舶信息dialog -->
      <el-dialog dialog title="船舶信息" :visible.sync="flag.shipDialog" v-if="JSON.stringify(currentItem) != '{}' && flag.enableEdit" width="1200px">
        <el-form :inline="true" :model="currentItem.ship" label-width="130px">
          <div style="display: flex; margin-bottom: 16px;">
            <el-form-item prop="shipImg1" label="船舶证1">
              <UploadImg class="uploadImg" :loading="currentItem.ship.shipImg1.loading" :uploaded="currentItem.ship.shipImg1.uploaded" :picUrl="currentItem.ship.shipImg1.picUrl" @update:file="updateFile" @change="uploadImg(currentItem.ship.shipImg1, 'Ships')" @deleteImg="deleteImg(currentItem.ship.shipImg1)"></UploadImg>
            </el-form-item>
            <el-form-item prop="shipImg1" label="船舶证2">
              <UploadImg class="uploadImg" :loading="currentItem.ship.shipImg2.loading" :uploaded="currentItem.ship.shipImg2.uploaded" :picUrl="currentItem.ship.shipImg2.picUrl" @update:file="updateFile" @change="uploadImg(currentItem.ship.shipImg2, 'Ships')" @deleteImg="deleteImg(currentItem.ship.shipImg2)"></UploadImg>
            </el-form-item>
          </div>
          <div>
            <el-form-item prop="number" label="编号">
              <el-input v-model="currentItem.ship.number"></el-input>
            </el-form-item>
            <el-form-item prop="shipsName" label="船名">
              <el-input v-model="currentItem.ship.shipsName"></el-input>
            </el-form-item>
            <el-form-item prop="formerName" label="曾用名">
              <el-input v-model="currentItem.ship.formerName"></el-input>
            </el-form-item>
          </div>
          <div>
            <el-form-item prop="homePort" label="船籍港">
              <el-input v-model="currentItem.ship.homePort"></el-input>
            </el-form-item>
            <el-form-item prop="shipRegistrationNumber" label="船舶登记号">
              <el-input v-model="currentItem.ship.shipRegistrationNumber"></el-input>
            </el-form-item>
            <el-form-item prop="surveyRegistratioNumber" label="船检登记号">
              <el-input v-model="currentItem.ship.surveyRegistratioNumber"></el-input>
            </el-form-item>
          </div>
          <div>
            <el-form-item prop="shipowner" label="船舶所有人">
              <el-input v-model="currentItem.ship.shipowner"></el-input>
            </el-form-item>
            <el-form-item prop="shipOperator" label="船舶经营人">
              <el-input v-model="currentItem.ship.shipOperator"></el-input>
            </el-form-item>
            <el-form-item prop="operatorPermitNumber" label="经营人许可证号码">
              <el-input v-model="currentItem.ship.operatorPermitNumber"></el-input>
            </el-form-item>
          </div>
          <div>
            <el-form-item prop="serviceLife" label="使用期限">
              <el-date-picker v-model="currentItem.ship.serviceLife" type="date" value-format="yyyy-MM-dd"></el-date-picker>
            </el-form-item>
            <el-form-item prop="licenceIssuingAuthority" label="发证机关">
              <el-input v-model="currentItem.ship.licenceIssuingAuthority"></el-input>
            </el-form-item>
            <el-form-item prop="dateOfIssue" label="发证日期">
              <el-date-picker v-model='currentItem.ship.dateOfIssue' type="date" value-format="yyyy-MM-dd"></el-date-picker>
            </el-form-item>
          </div>
          <div>
            <el-form-item prop="shipType" label="船舶类型">
              <el-input v-model="currentItem.ship.shipType"></el-input>
            </el-form-item>
            <el-form-item prop="shipMaterial" label="船舶材料">
              <el-input v-model="currentItem.ship.shipMaterial"></el-input>
            </el-form-item>
            <el-form-item prop="shipGrossTonnage" label="船舶总吨">
              <el-input v-model="currentItem.ship.shipGrossTonnage"></el-input>
            </el-form-item>
          </div>
          <div>
            <el-form-item prop="completionDate" label="建成日期">
              <el-date-picker v-model="currentItem.ship.completionDate" type="date" value-format="yyyy-MM-dd"></el-date-picker>
            </el-form-item>
            <el-form-item prop="redevelopmentDate" label="改建日期">
              <el-date-picker v-model="currentItem.ship.redevelopmentDate" type="date" value-format="yyyy-MM-dd"></el-date-picker>
            </el-form-item>
            <el-form-item prop="mainMotorPower" label="主机功率">
              <el-input v-model="currentItem.ship.mainMotorPower"></el-input>
            </el-form-item>
          </div>
          <div>
            <el-form-item prop="allowScope" label="船舶经营人许可证核定的经营范围" label-width="300px">
              <el-input class="row" v-model="currentItem.ship.allowScope"></el-input>
            </el-form-item>
          </div>
          <div>
            <el-form-item prop="stipulateScope" label="本船核定的经营范围" label-width="300px">
              <el-input class="row" v-model="currentItem.ship.stipulateScope"></el-input>
            </el-form-item>
          </div>
        </el-form>
        <div style="display:flex; justify-content: center;">
          <el-button type="primary" v-if="flag.enableEdit" @click="updateShipImageInfo(currentItem)">保存</el-button>
        </div>
      </el-dialog>

      <!-- 承运人信息dialog -->
      <el-dialog dialog title="承运人信息" :visible.sync="flag.personDialog" v-if="JSON.stringify(currentItem) != '{}' && !flag.enableEdit" width="1200px">
        <el-form :inline="true" :model="currentItem.person" label-width="155px">
          <div style="display: flex; margin-bottom: 16px;">
            <el-form-item prop="idCardFront" label="身份证正面" v-if="currentItem.person.idCardFront.picUrl">
              <el-image style="width: 177px; height: 177px; margin: 0 8px; border: 1px dashed #bbb" :src="currentItem.person.idCardFront.picUrl" :preview-src-list="[currentItem.person.idCardFront.picUrl]"></el-image>
            </el-form-item>
            <el-form-item prop="idCardBack" label="身份证反面" v-if="currentItem.person.idCardBack.picUrl">
              <el-image style="width: 177px; height: 177px; margin: 0 8px; border: 1px dashed #bbb" :src="currentItem.person.idCardBack.picUrl" :preview-src-list="[currentItem.person.idCardBack.picUrl]"></el-image>
            </el-form-item>
          </div>
          <div>
            <el-form-item prop="name" label="承运人姓名">
              <el-input v-model="currentItem.person.name" disabled></el-input>
            </el-form-item>
            <el-form-item prop="idNumber" label="承运人身份证号码">
              <el-input v-model="currentItem.person.idNumber" disabled></el-input>
            </el-form-item>
            <el-form-item prop="issuingAuthority" label="承运人身份证签发机关">
              <el-input v-model="currentItem.person.issuingAuthority" disabled></el-input>
            </el-form-item>
          </div>
          <div>
            <el-form-item prop="periodOfValidity" label="承运人身份证有效期">
              <el-date-picker v-model="currentItem.person.periodOfValidity" type="date" value-format="yyyy-MM-dd" disabled></el-date-picker>
            </el-form-item>
            <el-form-item prop="isLongTime" label="身份证是否长期有效">
              <el-checkbox v-model="currentItem.person.isLongTime" disabled></el-checkbox>
            </el-form-item>
          </div>
        </el-form>
      </el-dialog>
      <!-- 承运人信息dialog -->
      <el-dialog dialog title="承运人信息" :visible.sync="flag.personDialog" v-if="JSON.stringify(currentItem) != '{}' && flag.enableEdit" width="1200px">
        <el-form :inline="true" :model="currentItem.person" label-width="155px">
          <div style="display: flex; margin-bottom: 16px;">
            <el-form-item prop="idCardFront" label="身份证正面">
              <UploadImg class="uploadImg" :loading="currentItem.person.idCardFront.loading" :uploaded="currentItem.person.idCardFront.uploaded" :picUrl="currentItem.person.idCardFront.picUrl" @update:file="updateFile" @change="uploadImg(currentItem.person.idCardFront, 'Ships')" @deleteImg="deleteImg(currentItem.person.idCardFront)"></UploadImg>
            </el-form-item>
            <el-form-item prop="idCardBack" label="身份证反面">
              <UploadImg class="uploadImg" :loading="currentItem.person.idCardBack.loading" :uploaded="currentItem.person.idCardBack.uploaded" :picUrl="currentItem.person.idCardBack.picUrl" @update:file="updateFile" @change="uploadImg(currentItem.person.idCardBack, 'Ships')" @deleteImg="deleteImg(currentItem.person.idCardBack)"></UploadImg>
            </el-form-item>
          </div>
          <div>
            <el-form-item prop="name" label="承运人姓名">
              <el-input v-model="currentItem.person.name"></el-input>
            </el-form-item>
            <el-form-item prop="idNumber" label="承运人身份证号码">
              <el-input v-model="currentItem.person.idNumber"></el-input>
            </el-form-item>
            <el-form-item prop="issuingAuthority" label="承运人身份证签发机关">
              <el-input v-model="currentItem.person.issuingAuthority"></el-input>
            </el-form-item>
          </div>
          <div>
            <el-form-item prop="periodOfValidity" label="承运人身份证有效期">
              <el-date-picker v-model="currentItem.person.periodOfValidity" type="date" value-format="yyyy-MM-dd"></el-date-picker>
            </el-form-item>
            <el-form-item prop="isLongTime" label="身份证是否长期有效">
              <el-checkbox v-model="currentItem.person.isLongTime"></el-checkbox>
            </el-form-item>
          </div>
        </el-form>
        <div style="display:flex; justify-content: center;">
          <el-button type="primary" v-if="flag.enableEdit" @click="updatePersonImageInfo(currentItem)">保存</el-button>
        </div>
      </el-dialog>

      <!-- 轨迹dialog -->
      <el-dialog dialog title="轨迹信息" :visible.sync="flag.trackDialog" v-if="JSON.stringify(currentItem) != '{}' && !flag.enableEdit" width="60vw">
        <div style="display: flex; flex-wrap:wrap; margin-bottom: 16px;" v-if="currentItem.WaterTransImage.length > 1">
          <el-image style="width: 177px; height: 177px; margin: 0 8px; border: 1px dashed #bbb" v-for="(item, index) in currentItem.WaterTransImage" :key="index" :src="item.ImageUml" :preview-src-list="[item.ImageUml]"></el-image>
        </div>
        <div v-if="currentItem.WaterTransImage.length === 1">
          <img style="width: 55vw; height: 55vh" v-for="(item, index) in currentItem.WaterTransImage" :key="index" :src="item" />
        </div>
        <div style="display:flex; justify-content: center; margin-top: 16px;">
          <el-button type="primary" @click="queryTrack">轨迹查询</el-button>
        </div>
      </el-dialog>
      <!-- 轨迹dialog -->
      <el-dialog dialog title="轨迹信息" :visible.sync="flag.trackDialog" v-if="JSON.stringify(currentItem) != '{}' && flag.enableEdit" width="60vw">
        <div style="display: flex; flex-wrap:wrap; margin-bottom: 16px;">
          <UploadImg class="uploadImg" v-for="(item, index) in currentItem.WaterTransImage" :key="index" :loading="item.loading" :uploaded="item.uploaded" :picUrl="item.picUrl" @update:file="updateFile" @change="uploadImg(item, 'Ships')" @deleteImg="deleteImg(item)"></UploadImg>
          <div class="add" @click="currentItem.WaterTransImage.push({loading: false, uploaded: false, picUrl: ''})">+</div>
        </div>
        <div style="display:flex; justify-content: center;">
          <el-button type="primary" @click="queryTrack">轨迹查询</el-button>
          <el-button type="primary" v-if="flag.enableEdit" @click="updateTransImage(currentItem)">保存</el-button>
        </div>
      </el-dialog>

      <!-- 发票列表dialog -->
      <el-dialog dialog title="发票列表" :visible.sync="flag.invoiceDialog" v-if="JSON.stringify(currentItem) != '{}' && !flag.enableEdit" width="60vw">
        <div style="display: flex; flex-wrap:wrap; margin-bottom: 16px;">
          <el-image style="width: 177px; height: 177px; margin: 0 8px; border: 1px dashed #bbb" v-for="(item, index) in currentItem.InvoiceImage" :key="index" :src="item.ImageUml" :preview-src-list="[item.ImageUml]"></el-image>
        </div>
      </el-dialog>
      <el-dialog dialog title="发票列表" :visible.sync="flag.invoiceDialog" v-if="JSON.stringify(currentItem) != '{}' && flag.enableEdit" width="60vw">
        <div style="display:flex; flex-wrap:wrap; margin-bottom: 16px;">
          <UploadImg class="uploadImg" v-for="(item, index) in currentItem.InvoiceImage" :key="index" :loading="item.loading" :uploaded="item.uploaded" :picUrl="item.picUrl" @update:file="updateFile" @change="uploadImg(item, 'Ships')" @deleteImg="deleteImg(item)"></UploadImg>
          <div class="add" @click="currentItem.InvoiceImage.push({loading: false, uploaded: false, picUrl: ''})">+</div>
        </div>
        <div style="display:flex; justify-content: center;">
          <el-button type="primary" v-if="flag.enableEdit" @click="updateInvoiceImage(currentItem)">保存</el-button>
        </div>
      </el-dialog>

      <!-- 合同列表dialog -->
      <el-dialog dialog title="合同列表" :visible.sync="flag.contractDialog" v-if="JSON.stringify(currentItem) != '{}' && !flag.enableEdit" width="60vw">
        <div style="display:flex; flex-wrap:wrap; margin-bottom: 16px;">
          <el-image style="width: 177px; height: 177px; margin: 0 8px; border: 1px dashed #bbb" v-for="(item, index) in currentItem.SupplierContractImage" :key="index" :src="item.ImageUml" :preview-src-list="[item.ImageUml]"></el-image>
        </div>
      </el-dialog>
      <!-- 合同列表dialog -->
      <el-dialog dialog title="合同列表" :visible.sync="flag.contractDialog" v-if="JSON.stringify(currentItem) != '{}' && flag.enableEdit" width="60vw">
        <div style="display:flex; flex-wrap:wrap; margin-bottom: 16px;">
          <UploadImg class="uploadImg" v-for="(item, index) in currentItem.SupplierContractImage" :key="index" :loading="item.loading" :uploaded="item.uploaded" :picUrl="item.picUrl" @update:file="updateFile" @change="uploadImg(item, 'Ships')" @deleteImg="deleteImg(item)"></UploadImg>
          <div class="add" @click="currentItem.SupplierContractImage.push({loading: false, uploaded: false, picUrl: ''})">+</div>
        </div>
        <div style="display:flex; justify-content: center;">
          <el-button type="primary" v-if="flag.enableEdit" @click="updateContractImage(currentItem)">保存</el-button>
        </div>
      </el-dialog>

      <!-- 付款凭证dialog -->
      <el-dialog dialog title="付款凭证列表" :visible.sync="flag.payCertifictyDialog" v-if="JSON.stringify(currentItem) != '{}' && !flag.enableEdit" width="60vw">
        <div style="display:flex; flex-wrap:wrap; margin-bottom: 16px;">
          <el-image style="width: 177px; height: 177px; margin: 0 8px; border: 1px dashed #bbb" v-for="(item, index) in currentItem.PayCertificty" :key="index" :src="item.ImageUml" :preview-src-list="[item.ImageUml]"></el-image>
        </div>
      </el-dialog>
      <!-- 付款凭证dialog -->
      <el-dialog dialog title="付款凭证列表" :visible.sync="flag.payCertifictyDialog" v-if="JSON.stringify(currentItem) != '{}' && flag.enableEdit" width="60vw">
        <div style="display:flex; flex-wrap:wrap; margin-bottom: 16px;">
          <UploadImg class="uploadImg" v-for="(item, index) in currentItem.PayCertificty" :key="index" :loading="item.loading" :uploaded="item.uploaded" :picUrl="item.picUrl" @update:file="updateFile" @change="uploadImg(item, 'Ships')" @deleteImg="deleteImg(item)"></UploadImg>
          <div class="add" @click="currentItem.PayCertificty.push({loading: false, uploaded: false, picUrl: ''})">+</div>
        </div>
        <div style="display:flex; justify-content: center;">
          <el-button type="primary" v-if="flag.enableEdit" @click="updateCertificImage(currentItem)">保存</el-button>
        </div>
      </el-dialog>

      <!-- 其它列表dialog -->
      <el-dialog dialog title="其它列表" :visible.sync="flag.otherDialog" v-if="JSON.stringify(currentItem) != '{}' && !flag.enableEdit" width="60vw">
        <div style="display:flex; flex-wrap:wrap; margin-bottom: 16px;">
          <el-image style="width: 177px; height: 177px; margin: 0 8px; border: 1px dashed #bbb" v-for="(item, index) in currentItem.OthersCertificty" :key="index" :src="item.ImageUml" :preview-src-list="[item.ImageUml]"></el-image>
        </div>
      </el-dialog>
      <!-- 其它列表dialog -->
      <el-dialog dialog title="其它列表" :visible.sync="flag.otherDialog" v-if="JSON.stringify(currentItem) != '{}' && flag.enableEdit" width="60vw">
        <div style="display:flex; flex-wrap:wrap; margin-bottom: 16px;">
          <UploadImg class="uploadImg" v-for="(item, index) in currentItem.OthersCertificty" :key="index" :loading="item.loading" :uploaded="item.uploaded" :picUrl="item.picUrl" @update:file="updateFile" @change="uploadImg(item, 'Ships')" @deleteImg="deleteImg(item)"></UploadImg>
          <div class="add" @click="currentItem.OthersCertificty.push({loading: false, uploaded: false, picUrl: ''})">+</div>
        </div>
        <div style="display:flex; justify-content: center;">
          <el-button type="primary" v-if="flag.enableEdit" @click="updateOtherImage(currentItem)">保存</el-button>
        </div>
      </el-dialog>

      <!-- 详情 -->
      <el-dialog dialog title="五流合一" :visible.sync="flag.allInfoDialog" width="80vw">
        <el-divider content-position="left">
          <el-tag type="info">基本信息</el-tag>
        </el-divider>
        <el-form :inline="true" :model="currentItem" v-if="JSON.stringify(currentItem) != '{}'" label-width="130px">
          <el-form-item prop="WaterBillNum" label="运单号">
            <el-input v-model="currentItem.WaterBillNum" disabled></el-input>
          </el-form-item>
          <el-form-item prop="Shipper" label="托运人">
            <el-input v-model="currentItem.Shipper" disabled></el-input>
          </el-form-item>
          <el-form-item prop="CarrierIDCard" label="承运人身份证号码">
            <el-input v-model="currentItem.CarrierIDCard" disabled></el-input>
          </el-form-item>
          <el-form-item prop="ShipsName" label="船名">
            <el-input v-model="currentItem.ShipsName" disabled></el-input>
          </el-form-item>
          <el-form-item prop="Carrier" label="承运人">
            <el-input v-model="currentItem.Carrier" disabled></el-input>
          </el-form-item>
          <el-form-item prop="ShipsType" label="船舶种类">
            <el-input v-model="currentItem.ShipsType" disabled></el-input>
          </el-form-item>
          <el-form-item prop="GoodsName" label="货名">
            <el-input v-model="currentItem.GoodsName" disabled></el-input>
          </el-form-item>
          <el-form-item prop="Unit" label="单位">
            <el-input v-model="currentItem.Unit" disabled></el-input>
          </el-form-item>
          <el-form-item prop="Num" label="数量">
            <el-input v-model="currentItem.Num" disabled></el-input>
          </el-form-item>
          <el-form-item prop="TotalAmount" label="总金额（元）">
            <el-input v-model="currentItem.TotalAmount" disabled></el-input>
          </el-form-item>
          <el-form-item prop="StartingPoint" label="起始地">
            <el-input v-model="currentItem.StartingPoint" disabled></el-input>
          </el-form-item>
          <el-form-item prop="Destination" label="到达地">
            <el-input v-model="currentItem.Destination" disabled></el-input>
          </el-form-item>
          <el-form-item prop="DepartureTime" label="起运时间">
            <el-date-picker v-model="currentItem.DepartureTime" type="datetime" value-format="yyyy-MM-dd HH:mm:ss" placeholder="起运时间" disabled></el-date-picker>
          </el-form-item>
          <el-form-item prop="ArrivalTime" label="到达时间">
            <el-date-picker v-model="currentItem.ArrivalTime" type="datetime" value-format="yyyy-MM-dd HH:mm:ss" placeholder="到达时间" disabled></el-date-picker>
          </el-form-item>
          <el-form-item prop="EmergencyContactPhone" label="联系人号码">
            <el-input v-model="currentItem.EmergencyContactPhone" disabled></el-input>
          </el-form-item>
          <el-form-item prop="InvoiceNumber" label="发票号码">
            <el-input v-model="currentItem.InvoiceNumber" disabled></el-input>
          </el-form-item>
          <el-form-item prop="InvoiceTime" label="开票日期">
            <el-date-picker v-model="currentItem.InvoiceTime" type="date" value-format="yyyy-MM-dd" placeholder="开票日期" disabled></el-date-picker>
          </el-form-item>
          <el-form-item prop="CreateTime" label="提交时间">
            <el-date-picker v-model="currentItem.CreateTime" type="datetime" value-format="yyyy-MM-dd HH:mm:ss" placeholder="提交时间" disabled></el-date-picker>
          </el-form-item>
        </el-form>

        <el-divider content-position="left">承运人信息</el-divider>
        <el-form :inline="true" :model="currentItem.ship" v-if="JSON.stringify(currentItem) != '{}'" label-width="130px">
          <div style="display: flex; margin-bottom: 16px;">
            <el-form-item prop="idCardFront" label="身份证正面" v-if="currentItem.person.idCardFront.picUrl">
              <el-image style="width: 177px; height: 177px; margin: 0 8px; border: 1px dashed #bbb" :src="currentItem.person.idCardFront.picUrl" :preview-src-list="[currentItem.person.idCardFront.picUrl]"></el-image>
            </el-form-item>
            <el-form-item prop="idCardBack" label="身份证反面" v-if="currentItem.person.idCardBack.picUrl">
              <el-image style="width: 177px; height: 177px; margin: 0 8px; border: 1px dashed #bbb" :src="currentItem.person.idCardBack.picUrl" :preview-src-list="[currentItem.person.idCardBack.picUrl]"></el-image>
            </el-form-item>
          </div>
          <el-form-item prop="name" label="承运人姓名" label-width="200px">
            <el-input v-model="currentItem.person.name" disabled></el-input>
          </el-form-item>
          <el-form-item prop="idNumber" label="承运人身份证号码" label-width="200px">
            <el-input v-model="currentItem.person.idNumber" disabled></el-input>
          </el-form-item>
          <el-form-item prop="issuingAuthority" label="承运人身份证签发机关" label-width="200px">
            <el-input v-model="currentItem.person.issuingAuthority" disabled></el-input>
          </el-form-item>
          <el-form-item prop="periodOfValidity" label="承运人身份证有效期" label-width="200px">
            <el-date-picker v-model="currentItem.person.periodOfValidity" type="date" value-format="yyyy-MM-dd" disabled></el-date-picker>
          </el-form-item>
          <el-form-item prop="isLongTime" label="身份证是否长期有效" label-width="200px">
            <el-checkbox v-model="currentItem.person.isLongTime" disabled></el-checkbox>
          </el-form-item>
        </el-form>

        <el-divider content-position="left">船舶信息</el-divider>
        <el-form :inline="true" :model="currentItem.ship" v-if="JSON.stringify(currentItem) != '{}'" label-width="130px">
          <div style="display: flex; margin-bottom: 16px;">
            <el-form-item prop="shipImg1" label="船舶证1">
              <el-image style="width: 177px; height: 177px; margin: 8px; border: 1px dashed #bbb" :src="currentItem.ship.shipImg1.picUrl" :preview-src-list="[currentItem.ship.shipImg1.picUrl]"></el-image>
            </el-form-item>
            <el-form-item prop="shipImg1" label="船舶证2">
              <el-image style="width: 177px; height: 177px; margin: 8px; border: 1px dashed #bbb" :src="currentItem.ship.shipImg2.picUrl" :preview-src-list="[currentItem.ship.shipImg2.picUrl]"></el-image>
            </el-form-item>
          </div>
          <el-form-item prop="number" label="编号">
            <el-input v-model="currentItem.ship.number" disabled></el-input>
          </el-form-item>
          <el-form-item prop="shipsName" label="船名">
            <el-input v-model="currentItem.ship.shipsName" disabled></el-input>
          </el-form-item>
          <el-form-item prop="formerName" label="曾用名">
            <el-input v-model="currentItem.ship.formerName" disabled></el-input>
          </el-form-item>
          <el-form-item prop="homePort" label="船籍港">
            <el-input v-model="currentItem.ship.homePort" disabled></el-input>
          </el-form-item>
          <el-form-item prop="shipRegistrationNumber" label="船舶登记号">
            <el-input v-model="currentItem.ship.shipRegistrationNumber" disabled></el-input>
          </el-form-item>
          <el-form-item prop="surveyRegistratioNumber" label="船检登记号">
            <el-input v-model="currentItem.ship.surveyRegistratioNumber" disabled></el-input>
          </el-form-item>
          <el-form-item prop="shipowner" label="船舶所有人">
            <el-input v-model="currentItem.ship.shipowner" disabled></el-input>
          </el-form-item>
          <el-form-item prop="shipOperator" label="船舶经营人">
            <el-input v-model="currentItem.ship.shipOperator" disabled></el-input>
          </el-form-item>
          <el-form-item prop="operatorPermitNumber" label="经营人许可证号码">
            <el-input v-model="currentItem.ship.operatorPermitNumber" disabled></el-input>
          </el-form-item>
          <el-form-item prop="serviceLife" label="使用期限">
            <el-date-picker v-model="currentItem.ship.serviceLife" type="date" value-format="yyyy-MM-dd" disabled></el-date-picker>
          </el-form-item>
          <el-form-item prop="licenceIssuingAuthority" label="发证机关">
            <el-input v-model="currentItem.ship.licenceIssuingAuthority" disabled></el-input>
          </el-form-item>
          <el-form-item prop="dateOfIssue" label="发证日期">
            <el-date-picker v-model='currentItem.ship.dateOfIssue' type="date" value-format="yyyy-MM-dd" disabled></el-date-picker>
          </el-form-item>
          <el-form-item prop="shipType" label="船舶类型">
            <el-input v-model="currentItem.ship.shipType" disabled></el-input>
          </el-form-item>
          <el-form-item prop="shipMaterial" label="船舶材料">
            <el-input v-model="currentItem.ship.shipMaterial" disabled></el-input>
          </el-form-item>
          <el-form-item prop="shipGrossTonnage" label="船舶总吨">
            <el-input v-model="currentItem.ship.shipGrossTonnage" disabled></el-input>
          </el-form-item>
          <el-form-item prop="completionDate" label="建成日期">
            <el-date-picker v-model="currentItem.ship.completionDate" type="date" value-format="yyyy-MM-dd" disabled></el-date-picker>
          </el-form-item>
          <el-form-item prop="redevelopmentDate" label="改建日期">
            <el-date-picker v-model="currentItem.ship.redevelopmentDate" type="date" value-format="yyyy-MM-dd" disabled></el-date-picker>
          </el-form-item>
          <el-form-item prop="mainMotorPower" label="主机功率">
            <el-input v-model="currentItem.ship.mainMotorPower" disabled></el-input>
          </el-form-item>
          <div>
            <el-form-item prop="allowScope" label="船舶经营人许可证核定的经营范围" label-width="300px">
              <el-input class="row" v-model="currentItem.ship.allowScope" disabled></el-input>
            </el-form-item>
          </div>
          <div>
            <el-form-item prop="stipulateScope" label="本船核定的经营范围" label-width="300px">
              <el-input class="row" v-model="currentItem.ship.stipulateScope" disabled></el-input>
            </el-form-item>
          </div>
        </el-form>

        <el-divider content-position="left">
          <el-tag type="danger">轨迹信息</el-tag>
        </el-divider>
        <div style="display: flex; flex-wrap:wrap; margin-bottom: 16px;" v-if="JSON.stringify(currentItem) != '{}' && flag.enableEdit">
          <el-image style="width: 177px; height: 177px; margin: 8px; border: 1px dashed #bbb" v-for="(item, index) in currentItem.WaterTransImage" :key="index" :src="item.picUrl" :preview-src-list="[item.picUrl]"></el-image>
        </div>
        <div style="display: flex; flex-wrap:wrap; margin-bottom: 16px;" v-if="JSON.stringify(currentItem) != '{}' && !flag.enableEdit">
          <el-image style="width: 177px; height: 177px; margin: 8px; border: 1px dashed #bbb" v-for="(item, index) in currentItem.WaterTransImage" :key="index" :src="item.ImageUml" :preview-src-list="[item.ImageUml]"></el-image>
        </div>
        <div style="display:flex; justify-content:center; align-items: center" @click="queryTrack">
          <p style="font-weight: 600; cursor: pointer; font-size: 16px; color: #2081FF; margin-top: 16px; text-decoration:underline">查看轨迹</p>
        </div>

        <el-divider content-position="left">
          <el-tag>发票列表</el-tag>
        </el-divider>
        <div style="display: flex; flex-wrap:wrap; margin-bottom: 16px;" v-if="JSON.stringify(currentItem) != '{}' && flag.enableEdit">
          <el-image style="width: 177px; height: 177px; margin: 8px; border: 1px dashed #bbb" v-for="(item, index) in currentItem.InvoiceImage" :key="index" :src="item.picUrl" :preview-src-list="[item.picUrl]"></el-image>
        </div>
        <div style="display: flex; flex-wrap:wrap; margin-bottom: 16px;" v-if="JSON.stringify(currentItem) != '{}' && !flag.enableEdit">
          <el-image style="width: 177px; height: 177px; margin: 8px; border: 1px dashed #bbb" v-for="(item, index) in currentItem.InvoiceImage" :key="index" :src="item.ImageUml" :preview-src-list="[item.ImageUml]"></el-image>
        </div>

        <el-divider content-position="left">
          <el-tag type="success">合同列表</el-tag>
        </el-divider>
        <div style="display: flex; flex-wrap:wrap; margin-bottom: 16px;" v-if="JSON.stringify(currentItem) != '{}' && flag.enableEdit">
          <el-image style="width: 177px; height: 177px; margin: 8px; border: 1px dashed #bbb" v-for="(item, index) in currentItem.SupplierContractImage" :key="index" :src="item.picUrl" :preview-src-list="[item.picUrl]"></el-image>
        </div>
        <div style="display:flex; flex-wrap:wrap; margin-bottom: 16px;" v-if="JSON.stringify(currentItem) != '{}' && !flag.enableEdit">
          <el-image style="width: 177px; height: 177px; margin: 8px; border: 1px dashed #bbb" v-for="(item, index) in currentItem.SupplierContractImage" :key="index" :src="item.ImageUml" :preview-src-list="[item.ImageUml]"></el-image>
        </div>

        <el-divider content-position="left">
          <el-tag type="warning">付款凭证列表</el-tag>
        </el-divider>
        <div style="display: flex; flex-wrap:wrap; margin-bottom: 16px;" v-if="JSON.stringify(currentItem) != '{}' && flag.enableEdit">
          <el-image style="width: 177px; height: 177px; margin: 8px; border: 1px dashed #bbb" v-for="(item, index) in currentItem.PayCertificty" :key="index" :src="item.picUrl" :preview-src-list="[item.picUrl]"></el-image>
        </div>
        <div style="display:flex; flex-wrap:wrap; margin-bottom: 16px;" v-if="JSON.stringify(currentItem) != '{}' && !flag.enableEdit">
          <el-image style="width: 177px; height: 177px; margin: 8px; border: 1px dashed #bbb" v-for="(item, index) in currentItem.PayCertificty" :key="index" :src="item.ImageUml" :preview-src-list="[item.ImageUml]"></el-image>
        </div>

        <el-divider content-position="left">其它列表</el-divider>
        <div style="display: flex; flex-wrap:wrap; margin-bottom: 16px;" v-if="JSON.stringify(currentItem) != '{}' && flag.enableEdit">
          <el-image style="width: 177px; height: 177px; margin: 8px; border: 1px dashed #bbb" v-for="(item, index) in currentItem.OthersCertificty" :key="index" :src="item.picUrl" :preview-src-list="[item.picUrl]"></el-image>
        </div>
        <div style="display:flex; flex-wrap:wrap; margin-bottom: 16px;" v-if="JSON.stringify(currentItem) != '{}' && !flag.enableEdit">
          <el-image style="width: 177px; height: 177px; margin: 8px; border: 1px dashed #bbb" v-for="(item, index) in currentItem.OthersCertificty" :key="index" :src="item.ImageUml" :preview-src-list="[item.ImageUml]"></el-image>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import UploadImg from '@/components/commonCmpt/uploadImg'
import { getWaterTransBillsDetails, deleteWaterTransBills, uploadImages, updateBills, updatePersonImageInfo, updateShipImageInfo, updateContractImage, updateTransImage, updateCertificImage, updateOtherImage, updateInvoiceImage, waterTransBillsDetailsById } from '@/api/platform/waybillManage/waterTransport'
import { getSystemConfig } from '@/api/common/common'
export default {
  data() {
    return {
      flag: {
        enableEdit: false, //是否允许编辑
        shipDialog: false, //船舶dialog
        personDialog: false, //紧急联系人dialog
        trackDialog: false, //轨迹dialog
        invoiceDialog: false, //发票dialog
        contractDialog: false, //合同dialog
        payCertifictyDialog: false, //付款凭证dialog
        otherDialog: false, //其他dialog
        basicEditDialog: false, //基本信息编辑dialog
        allInfoDialog: false, //所有信息dialog
        showSelect: false, // 控制是否显示筛选框
      },
      pagination1: {
        page: 1,
        pagesize: 10,
        total: 0,
        loading: false,
      },
      formData: {
        shipper: '', //托运人
        carrier: '', //承运人
        startingPoint: '', //起始地
        destination: '', //到达地
        shipsName: '', //船名
        time: '', //起运时间
        createTime: '', //提交时间
        invoiceNum: '', //发票号码
        billId: '', //运单号
      },
      currentItem: {}, //当前正在操作的tableList1的哪个元素
      currentFile: null, //当前正在操作的文件
      tableList1: [], //表格数据
    }
  },
  methods: {
    //下载模板
    downloadTemplate() {
      location.href = "https://res.gts56.com/fzkj/bsc/Ship/Template/%E6%B0%B4%E8%BF%90%E8%A1%A8%E6%A0%BC.xlsx"
    },
    //去船讯网
    queryTrack() {
      window.open('http://www.shipxy.com/')
    },
    //设置当前组件正在操作的文件
    updateFile(file) {
      this.currentFile = file
    },
    //删除文件
    deleteImg(field) {
      field.uploaded = false
      field.loading = false
      field.picUrl = ''
    },
    //上传图片
    uploadImg(field, type) {
      field.loading = true
      let formData = new FormData()
      formData.append('file', this.currentFile)
      switch (type) {
        case 'Ships': //船舶
          formData.append('type', type)
          uploadImages(formData).then(res => {
            field.picUrl = res.ship
          }).finally(() => {
            field.loading = false
            field.uploaded = true
          })
          break
        case 'Trans': //轨迹
          formData.append('type', type)
          uploadImages(formData).then(res => {
            field.picUrl = res.ship
          }).finally(() => {
            field.loading = false
            field.uploaded = true
          })
          break
        case 'Person': //联系人身份证
          formData.append('type', type)
          uploadImages(formData).then(res => {
            field.picUrl = res.ship
          }).finally(() => {
            field.loading = false
            field.uploaded = true
          })
          break
        case 'Contract': //合同
          formData.append('type', type)
          uploadImages(formData).then(res => {
            field.picUrl = res.ship
          }).finally(() => {
            field.loading = false
            field.uploaded = true
          })
          break
        case 'PayCertificate': //付款凭证
          formData.append('type', type)
          uploadImages(formData).then(res => {
            field.picUrl = res.ship
          }).finally(() => {
            field.loading = false
            field.uploaded = true
          })
          break
        case 'Invoice': //发票
          formData.append('type', type)
          uploadImages(formData).then(res => {
            field.picUrl = res.ship
          }).finally(() => {
            field.loading = false
            field.uploaded = true
          })
          break
        case 'OtherCertificate': //其它凭证
          formData.append('type', type)
          uploadImages(formData).then(res => {
            field.picUrl = res.ship
          }).finally(() => {
            field.loading = false
            field.uploaded = true
          })
          break
      }
    },
    //重置
    reset() {
      this.$refs['formData'].resetFields()
      this.search()
    },
    //分页回调
    pagination1Change(e) {
      this.pagination1.page = e
      this.pagination1.loading = true
      let params = {
        pageIndex: this.pagination1.page,
        pageSize: this.pagination1.pagesize,
        Shipper: this.formData.shipper,
        Carrier: this.formData.carrier,
        ShipsName: this.formData.shipsName,
        StartingPoint: this.formData.startingPoint,
        Destination: this.formData.destination,
        InvoiceNum: this.formData.invoiceNum,
        billId: this.formData.billId,
      }
      if (this.formData.createTime) {
        params.CreateTime1 = this.formData.createTime[0] ? this.formData.createTime[0] : ''
        params.CreateTime2 = this.formData.createTime[1] ? this.formData.createTime[1] : ''
      }
      if (this.formData.time) {
        params.DepartureTime = this.formData.time[0] ? this.formData.time[0] : ''
        params.ArrivalTime = this.formData.time[1] ? this.formData.time[1] : ''
      }
      getWaterTransBillsDetails(params).then(res => {
        this.tableList1 = res.waterInfo.map(item => {
          item.ship = JSON.parse(item.ShipJson)
          item.person = JSON.parse(item.PersonJson)
          item.ship.shipImg1 = { //船舶证1
            loading: false,
            uploaded: false,
            picUrl: item.ship.shipImg1,
          }
          item.ship.shipImg2 = { //船舶证2
            loading: false,
            uploaded: false,
            picUrl: item.ship.shipImg2,
          }
          item.person.idCardFront = { //身份证正面
            loading: false,
            uploaded: false,
            picUrl: item.person.idCardFront,
          }
          item.person.idCardBack = { //身份证反面
            loading: false,
            uploaded: false,
            picUrl: item.person.idCardBack,
          }
          if (this.flag.enableEdit) {
            item.InvoiceImage = item.InvoiceImage.map(item => { return { loading: false, uploaded: false, picUrl: item.ImageUml, id: item.ID } })
            item.OthersCertificty = item.OthersCertificty.map(item => { return { loading: false, uploaded: false, picUrl: item.ImageUml, id: item.ID } })
            item.PayCertificty = item.PayCertificty.map(item => { return { loading: false, uploaded: false, picUrl: item.ImageUml, id: item.ID } })
            item.SupplierContractImage = item.SupplierContractImage.map(item => { return { loading: false, uploaded: false, picUrl: item.ImageUml, id: item.ID } })
            item.WaterTransImage = item.WaterTransImage.map(item => { return { loading: false, uploaded: false, picUrl: item.ImageUml, id: item.ID } })
          }
          return item
        })
        this.pagination1.total = res.Count
      }).finally(() => {
        this.pagination1.loading = false
      })
    },
    //打开所有信息弹窗
    openAllInfoDialog(e) {
      this.currentItem = e
      this.flag.allInfoDialog = true
    },
    //打开编辑弹窗
    openBasicEditDialogDialog(e) {
      this.currentItem = e
      this.flag.basicEditDialog = true
    },
    //打开发票弹窗
    openInvoiceDialog(e) {
      this.currentItem = e
      this.flag.invoiceDialog = true
    },
    //打开合同弹窗
    openContractDialog(e) {
      this.currentItem = e
      this.flag.contractDialog = true
    },
    //打开其它弹窗
    openOtherDialog(e) {
      this.currentItem = e
      this.flag.otherDialog = true
    },
    //付款凭证弹窗
    openPayCertifictyDialog(e) {
      this.currentItem = e
      this.flag.payCertifictyDialog = true
    },
    //打开紧急联系人弹窗
    openPersonDialog(e) {
      this.currentItem = e
      this.flag.personDialog = true
    },
    //打开船舶dialog
    openShipDialog(e) {
      this.currentItem = e
      this.flag.shipDialog = true
    },
    //打开轨迹dialog
    openTrackDialog(e) {
      this.currentItem = e
      this.flag.trackDialog = true
    },
    //删除运单
    deleteWaybill(e) {
      this.$confirm('确认删除该运单吗', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let params = {
          id: e.WaterBillNum
        }
        deleteWaterTransBills(params).then(res => {
          this.$router.go(0)
        })
      }).catch()
    },
    //获取运单列表
    getWaterTransBillsDetails() {
      this.pagination1.loading = true
      let params = {
        pageIndex: this.pagination1.page,
        pageSize: this.pagination1.pagesize
      }
      getWaterTransBillsDetails(params).then(res => {
        this.tableList1 = res.waterInfo.map(item => {
          item.ship = JSON.parse(item.ShipJson)
          item.person = JSON.parse(item.PersonJson)
          item.ship.shipImg1 = { //船舶证1
            loading: false,
            uploaded: false,
            picUrl: item.ship.shipImg1,
          }
          item.ship.shipImg2 = { //船舶证2
            loading: false,
            uploaded: false,
            picUrl: item.ship.shipImg2,
          }
          item.person.idCardFront = { //身份证正面
            loading: false,
            uploaded: false,
            picUrl: item.person.idCardFront,
          }
          item.person.idCardBack = { //身份证反面
            loading: false,
            uploaded: false,
            picUrl: item.person.idCardBack,
          }
          //如果是可编辑状态
          if (this.flag.enableEdit) {
            item.InvoiceImage = item.InvoiceImage.map(item => { return { loading: false, uploaded: false, picUrl: item.ImageUml, id: item.ID } })
            item.OthersCertificty = item.OthersCertificty.map(item => { return { loading: false, uploaded: false, picUrl: item.ImageUml, id: item.ID } })
            item.PayCertificty = item.PayCertificty.map(item => { return { loading: false, uploaded: false, picUrl: item.ImageUml, id: item.ID } })
            item.SupplierContractImage = item.SupplierContractImage.map(item => { return { loading: false, uploaded: false, picUrl: item.ImageUml, id: item.ID } })
            item.WaterTransImage = item.WaterTransImage.map(item => { return { loading: false, uploaded: false, picUrl: item.ImageUml, id: item.ID } })
          }
          return item
        })
        this.pagination1.total = res.Count
      }).finally(() => {
        this.pagination1.loading = false
      })
    },
    //搜索
    search() {
      this.pagination1.loading = true
      let params = {
        pageIndex: 1,
        pageSize: this.pagination1.pagesize,
        Shipper: this.formData.shipper,
        Carrier: this.formData.carrier,
        ShipsName: this.formData.shipsName,
        StartingPoint: this.formData.startingPoint,
        Destination: this.formData.destination,
        InvoiceNum: this.formData.invoiceNum,
        billId: this.formData.billId,
      }
      if (this.formData.createTime) {
        params.CreateTime1 = this.formData.createTime[0] ? this.formData.createTime[0] : ''
        params.CreateTime2 = this.formData.createTime[1] ? this.formData.createTime[1] : ''
      }
      if (this.formData.time) {
        params.DepartureTime = this.formData.time[0] ? this.formData.time[0] : ''
        params.ArrivalTime = this.formData.time[1] ? this.formData.time[1] : ''
      }
      getWaterTransBillsDetails(params).then(res => {
        this.tableList1 = res.waterInfo.map(item => {
          item.ship = JSON.parse(item.ShipJson)
          item.person = JSON.parse(item.PersonJson)
          item.ship.shipImg1 = { //船舶证1
            loading: false,
            uploaded: false,
            picUrl: item.ship.shipImg1,
          }
          item.ship.shipImg2 = { //船舶证2
            loading: false,
            uploaded: false,
            picUrl: item.ship.shipImg2,
          }
          item.person.idCardFront = { //身份证正面
            loading: false,
            uploaded: false,
            picUrl: item.person.idCardFront,
          }
          item.person.idCardBack = { //身份证反面
            loading: false,
            uploaded: false,
            picUrl: item.person.idCardBack,
          }
          if (this.flag.enableEdit) {
            item.InvoiceImage = item.InvoiceImage.map(item => { return { loading: false, uploaded: false, picUrl: item.ImageUml, id: item.ID } })
            item.OthersCertificty = item.OthersCertificty.map(item => { return { loading: false, uploaded: false, picUrl: item.ImageUml, id: item.ID } })
            item.PayCertificty = item.PayCertificty.map(item => { return { loading: false, uploaded: false, picUrl: item.ImageUml, id: item.ID } })
            item.SupplierContractImage = item.SupplierContractImage.map(item => { return { loading: false, uploaded: false, picUrl: item.ImageUml, id: item.ID } })
            item.WaterTransImage = item.WaterTransImage.map(item => { return { loading: false, uploaded: false, picUrl: item.ImageUml, id: item.ID } })
          }
          return item
        })
        this.pagination1.total = res.Count
      }).finally(() => {
        this.pagination1.loading = false
      })
    },
    //获取是否可编辑状态
    getSystemConfig() {
      getSystemConfig({ key: 'OnLineEidtConfig' }).then(res => {
        this.flag.enableEdit = res.patterSetInfo.KeyValue == 0 ? true : false
        this.getWaterTransBillsDetails()
      })
    },
    //更新水运信息
    updateBills(item) {
      let params = JSON.parse(JSON.stringify(item))
      delete (params.ship)
      delete (params.person)
      delete (params.ShipJson)
      delete (params.PersonJson)
      delete (params.WaterTransImage)
      delete (params.SupplierContractImage)
      delete (params.PayCertificty)
      delete (params.OthersCertificty)
      delete (params.InvoiceImage)
      updateBills(params).then(res => {
        this.waterTransBillsDetailsById(item.WaterBillNum)
        this.flag.basicEditDialog = false
      })
    },
    //更新承运人信息
    updatePersonImageInfo(item) {
      let obj = JSON.parse(JSON.stringify(item.person))
      obj.idCardBack = obj.idCardBack.picUrl
      obj.idCardFront = obj.idCardFront.picUrl
      let params = {
        person: JSON.stringify(obj),
        waterBillNum: item.WaterBillNum
      }
      updatePersonImageInfo(params).then(res => {
        this.waterTransBillsDetailsById(item.WaterBillNum)
        this.flag.personDialog = false
      })
    },
    //更新船舶信息
    updateShipImageInfo(item) {
      let obj = JSON.parse(JSON.stringify(item.ship))
      obj.shipImg1 = obj.shipImg1.picUrl
      obj.shipImg2 = obj.shipImg2.picUrl
      let params = {
        ship: JSON.stringify(obj),
        waterBillNum: item.WaterBillNum
      }
      updateShipImageInfo(params).then(res => {
        this.waterTransBillsDetailsById(item.WaterBillNum)
        this.flag.shipDialog = false
      })
    },
    //更新合同照片
    updateContractImage(item) {
      let imgList = []
      item.SupplierContractImage.forEach(el => {
        if (el.id && el.picUrl) { //有id是之前就上传  现在做修改
          imgList.push({ id: el.id, ImageUml: el.picUrl })
        } else if (el.id && !el.picUrl) { //有id没有url是做了删除操作的
          imgList.push({ id: el.id })
        } else if (el.picUrl) { //没有id是新长传的
          imgList.push({ ImageUml: el.picUrl })
        }
      })
      let parmas = {
        waterBillNum: item.WaterBillNum,
        ImageUml: imgList,
      }
      updateContractImage(parmas).then(res => {
        this.waterTransBillsDetailsById(item.WaterBillNum)
        this.flag.contractDialog = false
      })
    },
    //更新轨迹照片
    updateTransImage(item) {
      let imgList = []
      item.WaterTransImage.forEach(el => {
        if (el.id && el.picUrl) { //有id是之前就上传  现在做修改
          imgList.push({ id: el.id, ImageUml: el.picUrl })
        } else if (el.id && !el.picUrl) { //有id没有url是做了删除操作的
          imgList.push({ id: el.id })
        } else if (el.picUrl) { //没有id是新长传的
          imgList.push({ ImageUml: el.picUrl })
        }
      })
      let parmas = {
        waterBillNum: item.WaterBillNum,
        ImageUml: imgList,
      }
      updateTransImage(parmas).then(res => {
        this.waterTransBillsDetailsById(item.WaterBillNum)
        this.flag.trackDialog = false
      })
    },
    //更新付款凭证照片
    updateCertificImage(item) {
      let imgList = []
      item.PayCertificty.forEach(el => {
        if (el.id && el.picUrl) { //有id是之前就上传  现在做修改
          imgList.push({ id: el.id, ImageUml: el.picUrl })
        } else if (el.id && !el.picUrl) { //有id没有url是做了删除操作的
          imgList.push({ id: el.id })
        } else if (el.picUrl) { //没有id是新长传的
          imgList.push({ ImageUml: el.picUrl })
        }
      })
      let parmas = {
        waterBillNum: item.WaterBillNum,
        ImageUml: imgList,
      }
      updateCertificImage(parmas).then(res => {
        this.waterTransBillsDetailsById(item.WaterBillNum)
        this.flag.payCertifictyDialog = false
      })
    },
    //更新其他凭证照片
    updateOtherImage(item) {
      let imgList = []
      item.OthersCertificty.forEach(el => {
        if (el.id && el.picUrl) { //有id是之前就上传  现在做修改
          imgList.push({ id: el.id, ImageUml: el.picUrl })
        } else if (el.id && !el.picUrl) { //有id没有url是做了删除操作的
          imgList.push({ id: el.id })
        } else if (el.picUrl) { //没有id是新长传的
          imgList.push({ ImageUml: el.picUrl })
        }
      })
      let parmas = {
        waterBillNum: item.WaterBillNum,
        ImageUml: imgList,
      }
      updateOtherImage(parmas).then(res => {
        this.waterTransBillsDetailsById(item.WaterBillNum)
        this.flag.otherDialog = false
      })
    },
    //更新发票照片
    updateInvoiceImage(item) {
      let imgList = []
      item.InvoiceImage.forEach(el => {
        if (el.id && el.picUrl) { //有id是之前就上传  现在做修改
          imgList.push({ id: el.id, ImageUml: el.picUrl })
        } else if (el.id && !el.picUrl) { //有id没有url是做了删除操作的
          imgList.push({ id: el.id })
        } else if (el.picUrl) { //没有id是新长传的
          imgList.push({ ImageUml: el.picUrl })
        }
      })
      let parmas = {
        waterBillNum: item.WaterBillNum,
        ImageUml: imgList,
      }
      updateInvoiceImage(parmas).then(res => {
        this.waterTransBillsDetailsById(item.WaterBillNum)
        this.flag.invoiceDialog = false
      })
    },
    //通过运单id查一条数据
    waterTransBillsDetailsById(wayBillId) {
      this.pagination1.loading = true
      let params = {
        wayBillId: wayBillId
      }
      waterTransBillsDetailsById(params).then(res => {
        this.tableList1.forEach(item => {
          if (item.WaterBillNum === res.oneWater[0].WaterBillNum) {
            let InvoiceImage = []
            let OthersCertificty = []
            let PayCertificty = []
            let SupplierContractImage = []
            let WaterTransImage = []
            res.oneWater[0].InvoiceImage.forEach(item => {
              InvoiceImage.push({ loading: false, uploaded: false, picUrl: item.ImageUml, id: item.ID })
            })
            res.oneWater[0].OthersCertificty.forEach(item => {
              OthersCertificty.push({ loading: false, uploaded: false, picUrl: item.ImageUml, id: item.ID })
            })
            res.oneWater[0].PayCertificty.forEach(item => {
              PayCertificty.push({ loading: false, uploaded: false, picUrl: item.ImageUml, id: item.ID })
            })
            res.oneWater[0].SupplierContractImage.forEach(item => {
              SupplierContractImage.push({ loading: false, uploaded: false, picUrl: item.ImageUml, id: item.ID })
            })
            res.oneWater[0].WaterTransImage.forEach(item => {
              WaterTransImage.push({ loading: false, uploaded: false, picUrl: item.ImageUml, id: item.ID })
            })
            item = res.oneWater[0]
            item.InvoiceImage = InvoiceImage
            item.OthersCertificty = OthersCertificty
            item.PayCertificty = PayCertificty
            item.SupplierContractImage = SupplierContractImage
            item.WaterTransImage = WaterTransImage
            item.ship = JSON.parse(item.ShipJson)
            item.person = JSON.parse(item.PersonJson)
            item.ship.shipImg1 = { //船舶证1
              loading: false,
              uploaded: false,
              picUrl: item.ship.shipImg1,
            }
            item.ship.shipImg2 = { //船舶证2
              loading: false,
              uploaded: false,
              picUrl: item.ship.shipImg2,
            }
            item.person.idCardFront = { //身份证正面
              loading: false,
              uploaded: false,
              picUrl: item.person.idCardFront,
            }
            item.person.idCardBack = { //身份证反面
              loading: false,
              uploaded: false,
              picUrl: item.person.idCardBack,
            }
            this.currentItem = item
            this.pagination1.loading = false
          }
        })
      })
    },
  },

  created() {
    this.getSystemConfig()
  },
  components: {
    UploadImg
  }
}
</script>

<style lang="scss" scoped>
@import '../../../../assets/style/variable.scss';
.waterTransportPage {
  .uploadImg {
    margin: 0 8px 16px 8px;
  }
  .add {
    display: inline-block;
    width: 177px;
    height: 177px;
    line-height: 177px;
    text-align: center;
    border-radius: 5px;
    font-size: 100px;
    cursor: pointer;
    border: 1px dashed $borderGray;
  }
}
</style>
<style>
.waterTransportPage .el-input__inner {
  width: 220px;
}
.waterTransportPage .pagination .el-input__inner {
  width: 40px !important;
}

.row .el-input__inner {
  width: 770px !important;
}
.waterTransportPage .el-dialog__header {
  font-size: 18px !important;
  border-bottom: 1px solid #eee !important;
}
</style>